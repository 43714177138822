export class AppFormDetails {
    AccTypes: GetAccTypesResult[] = [];
    BackBankBinary: string = "";
    BackIDBinary: string = "";
    Countries: GetCountriesResult[] = [];
    Currency: GetAccCurrencyResult[] = [];
    Declarations: GetDeclarationsResult[] = [];
    FrontIDBinary: string = "";
    InfrontBankBinary: string = "";
    KYCComplianceAnswers: GetKYCComplianceAnswersResult = new GetKYCComplianceAnswersResult();
    KYCDetails: GetKYCDetailsResult = new GetKYCDetailsResult();
    KYCDocuments: Documents[] = [];
    Leverage: GetAccLeverageResult[] = [];
    ProofAddrBinary: string = "";
    Salutation: GetSalutationResult[] = [];
}
export class Documents {
    BackBankBinary: string = "";
    BackBankName: string = "";
    BackBankType: string = "";
    BackIDBinary: string = "";
    BackIDName: string = "";
    BackIDType: string = "";
    BankCardID: string = "";
    BankName: string = "";
    CardHolderName: string = "";
    DocCountryIssuanceID: string = "";
    DocExpiryDate: Date = new Date();
    DocIDNumber: string = "";
    FrontIDBinary: string = "";
    FrontIDName: string = "";
    FrontIDType: string = "";
    IDType: string = "";
    InfrontBankBinary: string = "";
    InfrontBankName: string = "";
    InfrontBankType: string = "";
    ProofAddrBinary: string = "";
    ProofAddressType: string = "";
    ProofAddressValid: boolean = false;
    ProofAddrName: string = "";
    ProofAddrType: string = "";
    SelfieBinary: string = "";
    SelfieName: string = "";
    SelfieType: string = "";
}
export class GetAccCurrencyResult {
    AccCurrency: string = "";
    AccCurrencyID: number = 0;
    AccCurrencyPerm: boolean = false;
    WLID: number = 0;
}
export class GetAccLeverageResult {
    AccLeverage: number = 0;
    AccLeverageID: number = 0;
    WLID: number = 0;
}
export class GetAccTypesResult {
    AccType: string = "";
    AccTypeID: number = 0;
    WLID: number = 0;
}
export class GetCountriesResult {
    CountryCode: string = "";
    CountryFlag: string = "";
    CountryID: number = 0;
    CountryName: string = "";
    CountryNameCN: string = "";
    CountryPerm: boolean = false;
    PoolID: number = 0;
}
export class GetDeclarationsResult {
    Answer: boolean = false;
    DAID: number = 0;
    DeclarationText: string = "";
    DID: number = 0;
    WithRadioBtn: boolean = false;
}

export class GetKYCComplianceAnswersResult {
    acctype: boolean = true;
    ADComment: string = "";
    Address: boolean = true;
    ADPrivilege: number = 0;
    AmountToInvest12Months: boolean = true;
    attendedAseminar: boolean = true;
    averageLeverageLevel: boolean = true;
    averageYearlyVolume: boolean = true;
    BackBankFileSrc: boolean = true;
    BackIDSrc: boolean = true;
    BackIDSrc2: boolean = true;
    BankCardID: boolean = true;
    BankName: boolean = true;
    basecurr: boolean = true;
    BuildingNbr: boolean = true;
    BusinessNature: boolean = true;
    CAID: number = 0;
    CardHolderName: boolean = true;
    CFDsKnowledgeLevel: boolean = true;
    CFDsTrades: boolean = true;
    CFDsYears: boolean = true;
    City: boolean = true;
    citytown: boolean = true;
    clntEmail: boolean = true;
    cob: boolean = true;
    CommoditiesKnowledgeLevel: boolean = true;
    CommoditiesTrades: boolean = true;
    CommoditiesYears: boolean = true;
    country: boolean = true;
    CountryBankAccount: boolean = true;
    CountryOfIssuance: boolean = true;
    CountryOfIssuance2: boolean = true;
    CountyStateProvince: boolean = true;
    curremployer: boolean = true;
    currentLevel: boolean = true;
    currposition: boolean = true;
    declaredBankruptcy: boolean = true;
    derivativesFutures: boolean = true;
    DOB: boolean = true;
    DocsComment: string = "";
    DocsPrivilege: number = 0;
    education: boolean = true;
    eduqualification: boolean = true;
    eduqualificationtxt: boolean = true;
    empstat: boolean = true;
    EstimatedAnnualIncome: boolean = true;
    EstimatedAnnualInvestment: boolean = true;
    EstimatedAnnualNetIncome: boolean = true;
    estimatedinvest: boolean = true;
    EstimatedLiquidNetWorth: boolean = true;
    EstimatedNetWorth: boolean = true;
    Experience: boolean = true;
    ExpiryDate: boolean = true;
    ExpiryDate2: boolean = true;
    familiarOTCderivatives: boolean = true;
    fname: boolean = true;
    foreignExchTrades: boolean = true;
    foreignExchYears: boolean = true;
    foreignKnowledgeLevel: boolean = true;
    FrontIDSrc: boolean = true;
    FrontIDSrc2: boolean = true;
    FuturesMarket: boolean = true;
    GovernmentKnowledgeLevel: boolean = true;
    GovernmentTrades: boolean = true;
    GovernmentYears: boolean = true;
    HowTradedPreviously: boolean = true;
    IDNumber: boolean = true;
    IDNumber2: boolean = true;
    IDType: boolean = true;
    IDType2: boolean = true;
    IIComment: string = "";
    IIPrivilege: number = 0;
    industryEmployed: boolean = true;
    industryRetired: boolean = true;
    industryStudent: boolean = true;
    InfrontBankFileSrc: boolean = true;
    initialMarginReq: boolean = true;
    instrumentsFamiliar: boolean = true;
    instrumentsWithMargin: boolean = true;
    KYCID: number = 0;
    LevelOfEducation: boolean = true;
    leverage: boolean = true;
    lname: boolean = true;
    MarginConcepts: boolean = true;
    MarginRequired: boolean = true;
    maritalStatus: boolean = true;
    marketMovingAgainstU: boolean = true;
    MaximumLeverageUsed: boolean = true;
    Member: boolean = true;
    mname: boolean = true;
    mnthlyavgvol: boolean = true;
    MonthlyExpenses: boolean = true;
    MonthlyRentalIncome: boolean = true;
    monthlyTrades: boolean = true;
    MutualfundsExchYears: boolean = true;
    MutualfundsKnowledgeLevel: boolean = true;
    MutualfundsTrades: boolean = true;
    NameEmployerOrganisation: boolean = true;
    nationality: boolean = true;
    NationalPassport: boolean = true;
    NbrOfDependents: boolean = true;
    NetValueWealth: boolean = true;
    Occupation: boolean = true;
    OptionsKnowledgeLevel: boolean = true;
    OptionsTrades: boolean = true;
    OptionsYears: boolean = true;
    OtherInvesKnowledgeLevel: boolean = true;
    OtherInvesTrades: boolean = true;
    OtherInvesYears: boolean = true;
    PDComment: string = "";
    PDPrivilege: number = 0;
    phone1: boolean = true;
    phone2: boolean = true;
    PlannedInitialInvestment: boolean = true;
    PlannedInvestment: boolean = true;
    PostalCode: boolean = true;
    prevemp: boolean = true;
    prevpos: boolean = true;
    Profession: boolean = true;
    ProfessionRelateFinancialIndustryQsn: boolean = true;
    ProofAddressType: boolean = true;
    proofOfAddress: boolean = true;
    ProofOfAddSrc: boolean = true;
    PurposeInvesting: boolean = true;
    PurposeOpening: boolean = true;
    purposetrading: boolean = true;
    QualThatAssistUnderstanding: boolean = true;
    resaddress: boolean = true;
    riskiestinv: boolean = true;
    RiskOfLoss: boolean = true;
    salutation: boolean = true;
    salutationtxt: boolean = true;
    SecondaryIncome: boolean = true;
    serverType: boolean = true;
    SharesKnowledgeLevel: boolean = true;
    SharesTrades: boolean = true;
    SharesYears: boolean = true;
    SourceOfFunds: boolean = true;
    sourceofwealth: boolean = true;
    SpecialDoc: string = "";
    SrcIncomeRetired: boolean = true;
    SrcIncomeStudent: boolean = true;
    state: boolean = true;
    stoploss: boolean = true;
    StopOutBeTriggered: boolean = true;
    StreetName: boolean = true;
    TAComment: string = "";
    TAPrivilege: number = 0;
    taxnb: boolean = true;
    taxnbtxt: boolean = true;
    taxrescntry: boolean = true;
    TDComment: string = "";
    TDPrivilege: number = 0;
    TEComment: string = "";
    TEPrivilege: number = 0;
    timeframePosition: boolean = true;
    TKComment: string = "";
    TKPrivilege: number = 0;
    TotalPortfolio: boolean = true;
    tradedForex: boolean = true;
    tradedFunds: boolean = true;
    tradingWithHigherLeverage: boolean = true;
    TrueAboutFuturesQns: boolean = true;
    UnderstandingOfRisks: boolean = true;
    understandOTCderivatives: boolean = true;
    UsedLeveragedProductsQsn: boolean = true;
    usperson: boolean = true;
    USResident: boolean = true;
    ValueRentalProp: boolean = true;
    volatilityConcepts: boolean = true;
    WhySpreadFloatingQns: boolean = true;
    zipcode: boolean = true;
}
export interface Sumbit {
    Compliance: GetKYCComplianceAnswersResult;
    ClientID: string;
    KYCID: number;
    SpecialDoc: string;
}
export class GetKYCDetailsResult {
    Status: number = 2;
    IdentificationDoc1Status: number = 2;
    IdentificationDoc2Status: number = 2;
    ProofAddDocStatus: number = 2;
    BankDetailsDocStatus: number = 2;
    TotalScore: number = 0;
    Address: string = "";
    AdminUsername: string = "";
    AmountToInvest12Months: string = "";
    ApprovedBy: string = "";
    AttendedCoursesQsn: boolean = false;
    AverageLevelLeverage: string = "";
    AverageYearlyVolume: string = "";
    BackBankPhoto: string = "";
    BackDocID: string = "";
    BackDocID2: string = "";
    BankCardID: string = "";
    BankingFacilities: boolean = false;
    BankingFacilitiesSF: boolean = false;
    BankName: string = "";
    BuildingNumber: string = "";
    BusinessOwnership: boolean = false;
    BusinessOwnershipSF: boolean = false;
    CardHolderName: string = "";
    CFDsLevel: string = "";
    CFDsTradesNo: string = "";
    CFDsYears: string = "";
    City: string = "";
    CommissionsRevenuesBonuses: boolean = false;
    CommissionsRevenuesBonusesSF: boolean = false;
    CommoditiesPreciousMetalsLevel: string = "";
    CommoditiesPreciousMetalsTradesNo: string = "";
    CommoditiesPreciousMetalsYears: string = "";
    Country: string = "";
    CountryBankAccount: number = 0;
    CountryBankAccountName: string = "";
    CountryID: number = 0;
    CountryOfBirth: string = "";
    CountryOfBirthID: number = 0;
    Creationdate: Date = new Date();
    CurrentEmployer: string = "";
    CurrentPosition: string = "";
    DayDOB: number = 0;
    DayDocExpiryDate: number = 0;
    DayDocExpiryDate2: number = 0;
    DeclaredBankruptcyQsn: boolean = false;
    DOB: Date = new Date();
    DocCountryIssuance: string = "";
    DocCountryIssuance2: string = "";
    DocCountryIssuanceID: number = 0;
    DocCountryIssuanceID2: number = 0;
    DocExpiryDate: Date = new Date();
    DocExpiryDate2: Date = new Date();
    DocIDNumber: string = "";
    DocIDNumber2: string = "";
    Education: string = "";
    EducationalFinancial: string = "";
    EducationalFinancialTXT: string = "";
    EmployerRegulatedFinancial: boolean = false;
    EmploymentAddress: string = "";
    EmploymentCity: string = "";
    EmploymentCountry: string = "";
    EmploymentPostalCode: string = "";
    EmploymentStatus: string = "";
    ESignature: string = "";
    EstimatedAmountInvest: string = "";
    EstimatedAnnualIncome: string = "";
    EstimatedAnnualInvestment: string = "";
    EstimatedAnnualNetIncome: string = "";
    EstimatedLiquidNetWorth: string = "";
    EstimatedNetWorth: string = "";
    FamilyIncomeRelationship: boolean = false;
    FamilyIncomeRelationshipSF: boolean = false;
    FName: string = "";
    ForeignExchangeLevel: string = "";
    ForeignExchangeTradesNo: string = "";
    ForeignExchangeYears: string = "";
    FrontDocID: string = "";
    FrontDocID2: string = "";
    FullName: string = "";
    FuturesMarketQns: boolean = false;
    GovernmentCorporateBondsLevel: string = "";
    GovernmentCorporateBondsTradesNo: string = "";
    GovernmentCorporateBondsYears: string = "";
    GUID: number = 0;
    HoldBachelorOtherSubjects: boolean = false;
    HoldProfessionalQualification: boolean = false;
    HowRoleProvidedExperience: string = "";
    HowTradedTheseProducts: string = "";
    IDType: string = "";
    IDType2: string = "";
    IFCommodities: boolean = false;
    IFCryptoCurrency: boolean = false;
    IFCurrencies: boolean = false;
    IFIndices: boolean = false;
    IFStocks: boolean = false;
    IncomeEmployment: boolean = false;
    IncomeEmploymentSF: boolean = false;
    Industry: string = "";
    industryEmpOther: string = "";
    IndustryRetired: string = "";
    industryRetiredOther: string = "";
    IndustryStudent: string = "";
    industryStudentOther: string = "";
    InfontBankPhoto: string = "";
    Inheritance: boolean = false;
    InheritanceSF: boolean = false;
    InitialPositionQns: boolean = false;
    InsurancePayout: boolean = false;
    InsurancePayoutSF: boolean = false;
    IsAdmin: boolean = false;
    IUnderstand: boolean = false;
    KYCID: number = 0;
    LevelKnowledgeFinancialMarkets: string = "";
    LName: string = "";
    LongHaveTraded: string = "";
    MarginRequiredQns: string = "";
    MaritalStatus: string = "";
    MaximumLeverageUsed: string = "";
    MName: string = "";
    MonthDOB: number = 0;
    MonthDocExpiryDate: number = 0;
    MonthDocExpiryDate2: number = 0;
    MonthlyAverageVolume: string = "";
    MonthlyRentalIncome: string = "";
    MutualFundsOfSharesLevel: string = "";
    MutualFundsOfSharesTradesNo: string = "";
    MutualFundsOfSharesYears: string = "";
    NameEmployerOrganisation: string = "";
    Nationality: string = "";
    NationalityID: number = 0;
    NationalPassportQsn: boolean = false;
    NatureOfBusiness: string = "";
    NetValueWealth: string = "";
    NoneAbove: boolean = false;
    NumberOfDependents: string = "";
    Occupation: string = "";
    OptionsOrFuturesLevel: string = "";
    OptionsOrFuturesTradesNo: string = "";
    OptionsOrFuturesYears: string = "";
    OTCDerivativesDifferentQns: boolean = false;
    OTCDerivativesPlacedQns: boolean = false;
    OtherInvestmentsLevel: string = "";
    OtherInvestmentsTradesNo: string = "";
    OtherInvestmentsYears: string = "";
    OtherSourceFunds: boolean = false;
    OtherSourceFundsTxt: string = "";
    OtherSrcWlth: boolean = false;
    OtherSrcWlthTxt: string = "";
    PersonalSaving: boolean = false;
    PersonalSavingSF: boolean = false;
    Phone: string = "";
    PlannedInitialInvestment: string = "";
    PlannedInvestment: string = "";
    PoliticallyPersonQsn: boolean = false;
    PreviousEmployer: string = "";
    PreviousPosition: string = "";
    Profession: string = "";
    ProfessionRelateFinancialIndustryQsn: boolean = false;
    ProofAddressDoc: string = "";
    ProofAddressType: string = "";
    ProofAddressValid: boolean = false;
    PropertyLandInvestments: boolean = false;
    PropertyLandInvestmentsSF: boolean = false;
    ProvideAverageNumber: string = "";
    PurposeInvesting: string = "";
    PurposeNatureTrading: string = "";
    PurposeOpeningAcc: string = "";
    QualificationLeveragedQsn: boolean = false;
    Reason: string = "";
    ReceivedHigherFinanceEducation: boolean = false;
    RelevantEmployerName: string = "";
    ReturnInvestmentsSharesDividends: boolean = false;
    ReturnInvestmentsSharesDividendsSF: boolean = false;
    RiskiestInvestmentQns: string = "";
    RoleHoldWithEmployer: string = "";
    RoleHoldWithEmployerOther: string = "";
    Salutation: string = "";
    SalutationID: number = 0;
    SalutationTxt: string = "";
    SecondaryIncomeQsn: boolean = false;
    SecondPhone: string = "";
    SelfieDoc: string = "";
    SharesLevel: string = "";
    SharesTradesNo: string = "";
    SharesYears: string = "";
    SizeFinancialPortfolio: boolean = false;
    SourceIncome: string = "";
    SourceOfIncomeStudent: string = "";
    State: string = "";
    StopLossOrderUsedQns: string = "";
    StopOutBeTriggered: string = "";
    StoppedOutSituationsQns: string = "";
    StreetName: string = "";
    TaxNumber: boolean = false;
    TaxNumberTxt: string = "";
    TaxResidencyCountry: string = "";
    TaxResidencyCountryID: number = 0;
    TimeframeHeldPositions: string = "";
    TINUnavailable: string = "";
    TINUnavailableTXT: string = "";
    TotalPortfolio: string = "";
    TradedSignificantSize: boolean = false;
    TradesPerMonth: string = "";
    TradingHigherLeverageApplyQns: string = "";
    TradingHigherLeverageQns: boolean = false;
    TrueAboutFuturesQns: string = "";
    TypeInstrumentsFamiliar: string = "";
    UnderstandConceptsVolatilityQsn: boolean = false;
    UnderstandLeveragedProductsQsn: boolean = false;
    UnderstandRisksWithTradingQsn: boolean = false;
    USAResidentQsn: boolean = false;
    USATaxPurposesQsn: boolean = false;
    UsedLeveragedProductsQsn: boolean = false;
    ValueRentalProp: string = "";
    WhatExtentTradedDerivatives: string = "";
    WhatExtentTradedFunds: string = "";
    WhatExtentTradedWithMargin: string = "";
    WhySpreadFloatingQns: string = "";
    WorkedRoleRelevantTrading: boolean = false;
    WorkExperienceQsn: boolean = false;
    WorkFinancialSector: boolean = false;
    YearDOB: number = 0;
    YearDocExpiryDate: number = 0;
    YearDocExpiryDate2: number = 0;
    ZipCode: string = "";
}
export class GetSalutationResult {
    Salutation: string = "";
    SalutationID: number = 0;
}



export class GetDynamicDropDownResult {
    DDID: number = 0;
    DDID1: number = 0;
    OID: number = 0;
    OptionName: string = "";
    OptionVal: string = "";
    SelectName: string = "";
}
export class GetKYCPermissionsResult {
    AccountType: boolean = false;
    AmountToInvest: boolean = false;
    AmountToInvest12Months: boolean = false;
    AnnualIncome: boolean = false;
    AnnualNetIncome: boolean = false;
    AttendedCoursesQsn: boolean = false;
    AverageLevelLeverage: boolean = false;
    AverageYearlyVolume: boolean = false;
    BankCardID: boolean = false;
    BankName: boolean = false;
    BankStatement: boolean = false;
    BaseCurrency: boolean = false;
    BrokerApplication: boolean = false;
    BrokerApplicationDesc: boolean = false;
    BrokerApplicationDescTxt: string = "";
    BuildingNumber: boolean = false;
    CardHolderName: boolean = false;
    CFDs: boolean = false;
    City: boolean = false;
    CommoditiesPreciousMetals: boolean = false;
    Country: boolean = false;
    CountryBankAccount: boolean = false;
    CountryIssuance: boolean = false;
    CountryIssuance2: boolean = false;
    CountryIssuanceDriverID: boolean = false;
    CountryIssuanceDriverID2: boolean = false;
    CountryIssuanceNationalID: boolean = false;
    CountryIssuanceNationalID2: boolean = false;
    CountryOfBirth: boolean = false;
    CurrentEmployer: boolean = false;
    CurrentPosition: boolean = false;
    DateOfBirth: boolean = false;
    DeclaredBankruptcyQsn: boolean = false;
    Documents: boolean = false;
    DocumentsDesc: boolean = false;
    DocumentsDescTxt: string = "";
    DriverLicenseID: boolean = false;
    DriverLicenseID2: boolean = false;
    Education: boolean = false;
    EducationalFinancial: boolean = false;
    EmailPerm: boolean = false;
    EmploymentAddress: boolean = false;
    EmploymentCity: boolean = false;
    EmploymentCountry: boolean = false;
    EmploymentPostalCode: boolean = false;
    EmploymentStatus: boolean = false;
    EstimatedAnnualInvestment: boolean = false;
    ExpiryDate: boolean = false;
    ExpiryDate2: boolean = false;
    ExpiryDateDriverID: boolean = false;
    ExpiryDateDriverID2: boolean = false;
    ExpiryDateNationalID: boolean = false;
    ExpiryDateNationalID2: boolean = false;
    FirstName: boolean = false;
    ForeignExchange: boolean = false;
    FuturesMarketQns: boolean = false;
    GovernmentAndCorporateBonds: boolean = false;
    HowTradedTheseProducts: boolean = false;
    Industry: boolean = false;
    IndustryRetired: boolean = false;
    IndustryStudent: boolean = false;
    InitialPositionQns: boolean = false;
    InvestorInfo: boolean = false;
    InvestorInfoDesc: boolean = false;
    InvestorInfoDescTxt: string = "";
    IsFillDocumentRequired: boolean = false;
    KPID: number = 0;
    KTID: number = 0;
    LastName: boolean = false;
    LetterFromGovernmentAgency: boolean = false;
    LevelEducationOurProducts: boolean = false;
    LevelKnowledgeFinancialMarkets: boolean = false;
    Leverage: boolean = false;
    LiquidNetWorth: boolean = false;
    LongHaveTraded: boolean = false;
    MarginRequiredQns: boolean = false;
    MaritalStatus: boolean = false;
    MaximumLeverageUsed: boolean = false;
    MiddleName: boolean = false;
    MonthlyAverageVolume: boolean = false;
    MonthlyExpenses: boolean = false;
    MonthlyRentalIncome: boolean = false;
    MutualFundsOfShares: boolean = false;
    NameEmployerOrganisation: boolean = false;
    NationalCardID: boolean = false;
    NationalCardID2: boolean = false;
    Nationality: boolean = false;
    NationalPassportQsn: boolean = false;
    NatureOfBusiness: boolean = false;
    NetValueWealth: boolean = false;
    NetWorth: boolean = false;
    NumberOfDependents: boolean = false;
    Occupation: boolean = false;
    OptionsOrFutures: boolean = false;
    OTCDerivativesDifferentQns: boolean = false;
    OTCDerivativesPlacedQns: boolean = false;
    OtherInvestments: boolean = false;
    OtherProofOfAddressType: boolean = false;
    PassportID: boolean = false;
    PassportID2: boolean = false;
    PassportNumber: boolean = false;
    PassportNumber2: boolean = false;
    PassportNumberDriverID: boolean = false;
    PassportNumberDriverID2: boolean = false;
    PassportNumberNationalID: boolean = false;
    PassportNumberNationalID2: boolean = false;
    PersonalDetails: boolean = false;
    PersonalDetailsDesc: boolean = false;
    PersonalDetailsDescTxt: string = "";
    Phone: boolean = false;
    PlannedInitialInvestment: boolean = false;
    PlannedInvestment: boolean = false;
    Platform: boolean = false;
    PoliticallyPersonQsn: boolean = false;
    PostalCode: boolean = false;
    PreviousEmployer: boolean = false;
    PreviousPosition: boolean = false;
    Profession: boolean = false;
    ProfessionalClientStatus: boolean = false;
    ProfessionalClientStatusDesc: boolean = false;
    ProfessionalClientStatusDescTxt: string = "";
    ProfessionRelateFinancialIndustryQsn: boolean = false;
    ProofAddressValid: boolean = false;
    PurposeAndNatureTrading: boolean = false;
    PurposeInvesting: boolean = false;
    PurposeOpeningAcc: boolean = false;
    QualificationLeveragedQsn: boolean = false;
    ResidentialAddress: boolean = false;
    RiskiestInvestmentQns: boolean = false;
    Salutation: boolean = false;
    SecondaryIncomeQsn: boolean = false;
    SecondPhone: boolean = false;
    Shares: boolean = false;
    SourceOfFunds: boolean = false;
    SourceOfIncome: boolean = false;
    SourceOfIncomeStudent: boolean = false;
    SourceOfWealth: boolean = false;
    State: boolean = false;
    StopLossOrderUsedQns: boolean = false;
    StopOutBeTriggered: boolean = false;
    StoppedOutSituationsQns: boolean = false;
    StreetName: boolean = false;
    TaxNumber: boolean = false;
    TaxResidencyCountry: boolean = false;
    TimeframeHeldPositions: boolean = false;
    TotalPortfolio: boolean = false;
    TraderDeclaration: boolean = false;
    TraderDeclarationDesc: boolean = false;
    TraderDeclarationDescTxt: string = "";
    TradesPerMonth: boolean = false;
    TradingExperience: boolean = false;
    TradingExperienceDesc: boolean = false;
    TradingExperienceDescTxt: string = "";
    TradingHigherLeverage: boolean = false;
    TradingHigherLeverageQns: boolean = false;
    TradingKnowledge: boolean = false;
    TradingKnowledgeDesc: boolean = false;
    TrueAboutFuturesQns: boolean = false;
    TradingKnowledgeDescTxt: string = "";
    TypeInstrumentsFamiliar: boolean = false;
    UnderstandConceptsVolatilityQsn: boolean = false;
    UnderstandLeveragedProductsQsn: boolean = false;
    UnderstandRisksWithTradingQsn: boolean = false;
    USAResidentQsn: boolean = false;
    USATaxPurposesQsn: boolean = false;
    UsedLeveragedProductsQsn: boolean = false;
    Utilitybill: boolean = false;
    ValueRentalProp: boolean = false;
    WhatExtentTradedDerivatives: boolean = false;
    WhatExtentTradedFunds: boolean = false;
    WhatExtentTradedWithMargin: boolean = false;
    WhySpreadFloatingQns: boolean = false;
    WorkExperienceQsn: boolean = false;
    IdentificationDoc1: boolean = false;
    IdentificationDoc2: boolean = false;
    ProofAddDoc: boolean = false;
    BankDetailsDoc: boolean = false;
}
export class GetScoringPointsResult {
    FieldID: string = "";
    FieldIndex: string = "";
    Score: number = 0;
    SID: number = 0;
    Value: string = "";
}
export class KYCPermissions {
    declarationslist: GetDeclarationsResult[] = [];
    dropDownlist: GetDynamicDropDownResult[] = [];
    permissionslist: GetKYCPermissionsResult = new GetKYCPermissionsResult();
    scoringpoints: GetScoringPointsResult[] = [];
}

export class BasicSelectOption  {
    value: string | number  = -1;
    label: string = "choose";

}


export class NotBasicSelectOption extends BasicSelectOption {
    accountid: string = "-1";

}
export class IpersonaldetailsTab {
    usaresident: boolean = false;
    politicallypersonqsn: boolean = false;
    declaredbankruptcyqsn: boolean = false;
    usataxpurposesqsn: boolean = false;
}

export class SelectOptionsClass<T> {
    Options: Array<T>;
    onChange: any;
    selectedOption: T;
    constructor() {
        this.Options = [] as Array<T>;
        this.onChange = () => { };
        this.selectedOption = {} as T;
    }
}
export class personaldetailsTextInput {
    firstname: string = "";
    lastname: string = "";
    middlename: string = "";
    phoneNumber: string = "";
    secondphoneNumberOptional: string = "";
    Email: string = "";
    residentialaddress: string = "";
    state: string = "";
    city: string = "";
    streetname: string = "";
    buildingnumber: string = "";
    postalcode: string = "";
}
export class personaldetailsTextInputLabel {
    firstname: boolean = false;
    lastname: boolean = false;
    middlename: boolean = false;
    phoneNumber: boolean = false;
    secondphoneNumberOptional: boolean = false;
    Email: boolean = false;
    residentialaddress: boolean = false;
    state: boolean = false;
    city: boolean = false;
    streetname: boolean = false;
    buildingnumber: boolean = false;
    postalcode: boolean = false;
}
export class personaldetailsSelectDropDown {
    salutation: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    dateofbirthDay: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    dateofbirthMonth: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    dateofbirthYear: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    countries: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    maritalstatus: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    nationality: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    countryResidence: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    countryofbirth: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
}
export class personaldetailsRadioBtns {
    usaresident: boolean = false;
    politicallypersonqsn: boolean = false;
    declaredbankruptcyqsn: boolean = false;
    usataxpurposesqsn: boolean = false;
}

export class investorinfoTextInput {
    //now are text 
    PlannedInvestment: string = "";
    EstimatedNetWorth: string = "";
    EstimatedAnnualIncome: string = "";
    ///
    Estimatedamounttoinvest: string = "";
    profession: string = "";
    numberofdependents: string = "";
    nameemployerorganisation: string = "";
    previousemployer: string = "";
    othersourcefunds: string = "";
    OtherSourceWealth: string = "";
    OtherindustryCurrentEmployment: string = "";
    OtherDoYouHaveIdentificationNumber: string = "";
    OtherSelectDoYouHaveIdentificationNumber: string = "";
    currentemployer: string = "";
    OtherindustryCurrentEmploymentOther: string = "";
    natureofbusiness: string = "";
    occupation: string = "";
    address: string = "";
    city: string = "";
    countystateprovince: string = "";
    postalcode: string = "";
    valuerentalprop: string = "";
    monthlyrentalincome: string = "";
    sourceofincomeRetired: string = "";
    OtherindustryCurrentEmploymentRetired: string = "";


}
export class investorinfoSelectDropDown {
    education: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    estimatednetworth: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    estimatedliquidnetworth: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    EstimatedAnnualnetworth: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    estimatedannualincome: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    totalportfoliovalueInUSD: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    plannedinvestment: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    PurposeOfInvesting: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    EstimatedamounttoinvestNextMonths: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    monthlyexpenses: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    estimatedannualinvestment: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    netvaluewealth: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    plannedinitialinvestment: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    employmentstatus: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    sourceofincome: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    previousposition: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    industryCurrentEmployment: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    taxresidencycountry: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    countrybankaccount: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    DoYouHaveIdentificationNumber: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    currentposition: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    industryCurrentEmploymentOther: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    industryCurrentEmploymentRetired: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    sourceofincomeRetired: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
}
export class investorinfoRadioBtns {
    DoYouHaveIdentificationNumber: boolean = false;
    DoYouHaveWorkexperience: boolean = false;
    HaveYouattendedaseminar: boolean = false;
    DoYouHaveAnationalpassportnumber: boolean = false;
}
export class investorinfoSourceFundsCheckBoxes {
    BusinessOwnership: boolean = false;
    PropertyLandInvestments: boolean = false;
    FamilyIncomeRelationship: boolean = false;
    PersonalSaving: boolean = false;
    InsurancePayout: boolean = false;
    IncomeFromEmployment: boolean = false;
    CommissionsRevenuesBonuses: boolean = false;
    ReturnOnInvestmentsSharesDividends: boolean = false;
    BankingFacilities: boolean = false;
    Inheritance: boolean = false;
    Other: boolean = false;
}
export class investorinfoCheckBoxes {
    SourceFundsList: string[] = [];
    sourceofwealthList: string[] = [];
    investorinfoSourceFundsCheckBoxes: investorinfoSourceFundsCheckBoxes = new investorinfoSourceFundsCheckBoxes();
    sourceofwealthCheckboxCheckBoxes: investorinfoSourceFundsCheckBoxes = new investorinfoSourceFundsCheckBoxes();
}
export class tradingexperienceSelectDropDown {
    monthlyaveragevolume: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    PurposeAndNatureOfTrading: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    PurposeOfOpeningTheAccount: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    currentlevelOfKnowledge: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowLongHaveYoutradedforex: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesDoYouPlaceEachMonth: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    OverWhatTimeframeHaveYouHeldPositions: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    StocksMutualFundsBondsEquities: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    DerivativesSuchAsFuturesOptionsSwaps: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ForexRollingSpotFXSpreadBets: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    WhatWasTheaverageyearlyvolume: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    WhatWasTheAverageLevelOfleverage: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    maximumleverageused: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
}
export class traderdeclarationResultList {//null as my come never choosen
    did: number = -1;
    answer: string | null = null;
}
export class documentsRadioBtns {//null as my come never choosen
    idtype: string = "";
    secondidtype: string = "";
    IsYourproofofaddressValid: string = "";
    proofofaddressType: string = "";
}
export class DocumentParameters {
    Token: string = "";
    CompanyName: string = "";
    objData: {
        ClientID: string;
        Type: string;
        BinaryDocument: any;
    } = { ClientID: "", Type: "", BinaryDocument: "" }
}
export class uploadFormType {
    Spinner: boolean = false;
    Name: string = "";
    Img: any = "";
}
export class documentsUpload {
    FrontID: uploadFormType = new uploadFormType();
    FrontIDNational: uploadFormType = new uploadFormType();
    FrontIDDriverLicense: uploadFormType = new uploadFormType();
    BackID: uploadFormType = new uploadFormType();
    BackIDNational: uploadFormType = new uploadFormType();
    BackIDLicense: uploadFormType = new uploadFormType();

    ProofOfAdd: uploadFormType = new uploadFormType();
    InfrontBank: uploadFormType = new uploadFormType();
    BackBank: uploadFormType = new uploadFormType();

    FrontID2: uploadFormType = new uploadFormType();
    FrontIDNational2: uploadFormType = new uploadFormType();
    FrontIDDriverLicense2: uploadFormType = new uploadFormType();
    BackID2: uploadFormType = new uploadFormType();
    BackIDNational2: uploadFormType = new uploadFormType();
    BackIDLicense2: uploadFormType = new uploadFormType();
}
export class documentsSelects {
    countryofissuance: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    countryofissuanceNationalIDcard: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryDay: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryMonth: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryYear: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryDayNationalIDcard: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryMonthNationalIDcard: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryYearNationalIDcard: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryDayDriversLicense: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryMonthDriversLicense: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryYearDriversLicense: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();

    countryofissuance2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    countryofissuanceNationalIDcard2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryDay2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryMonth2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryYear2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryDayNationalIDcard2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryMonthNationalIDcard2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryYearNationalIDcard2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryDayDriversLicense2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryMonthDriversLicense2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ExpiryYearDriversLicense2: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();

}
export class documentsTextInput {
    passportnumber: string = "";
    NationalIDCardNumber: string = "";
    DriversLicenseNumber: string = "";

    passportnumber2: string = "";
    NationalIDCardNumber2: string = "";
    DriversLicenseNumber2: string = "";

    cardholdername: string = "";
    CardHolderID: string = "";
    bankname: string = "";

}
export class tradingknowledgelevelofeducation {
    IHoldProfessionalQualification: boolean = false;
    IReceivedHigherEducation: boolean = false;
    IHoldABachelor: boolean = false;
    InTheLastThreeYears: boolean = false;
    NoneOfTheAbove: boolean = false;
}
export class tradingknowledgeCheckboxes {
    levelofeducationList: string[] = [];
    levelofeducationCheckBoxes: tradingknowledgelevelofeducation = new tradingknowledgelevelofeducation();
}
export class tradingknowledgeSelects {
    educationalQualification: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    ThestoplossOrderIsUsedTo: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    Investment: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowMuchMarginIsRequired: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    TheMarketIsMovingAgainstYou: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    Whentradingwithhigherleverage: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    stopoutbetriggered: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesHaveYouExecuted: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    LevelOfKnowledgeFE: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesHaveYouExecutedshares: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    LevelOfKnowledgeshares: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    LevelOfKnowledgeMutualFundsOfshares: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesHaveYouExecutedMutualFundsOfshares: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    LevelOfKnowledgegovernmentandcorporatebonds: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesHaveYouExecutedgovernmentandcorporatebonds: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    LevelOfKnowledgecfds: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesHaveYouExecutedcfds: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    LevelOfKnowledgeCommoditiesAndPreciousMetals: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesHaveYouExecutedCommoditiesAndPreciousMetals: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    LevelOfKnowledgeoptionsorfutures: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesHaveYouExecutedoptionsorfutures: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowManyTradesHaveYouExecutedotherinvestments: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    LevelOfKnowledgeotherinvestments: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    HowHaveYouTradedTheseProducts: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    whyspreadfloatingqns: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
}
export class tradingknowledgeTextInput {
    educationalQualificationTxtOther: string = "";
    YearsOfTrading: string = "";
    YearsOfTradingshares: string = "";
    YearsOfTradingMutualFundsOfshares: string = "";
    YearsOfTradinggovernmentandcorporatebonds: string = "";
    YearsOfTradingCommoditiesAndPreciousMetals: string = "";
    YearsOfTradingcfds: string = "";
    YearsOfTradingoptionsorfutures: string = "";
    YearsOfTradingotherinvestments: string = "";
}
export class tradingknowledgeRadioBtns {
    tradingwithhigherleverageIncfds: boolean = false;
    IfThevalueOfYourInitialPosition: boolean = false;
    futuresmarketAreTraded: boolean = false;
    DoYouHaveGoodUnderstandingOTCDeriv: boolean = false;
    DoYouHaveGoodUndersOTCDerivFamiliar: boolean = false;
}
export class professionalclientstatusSelects {
    provideaveragenumber: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
    roleholdwithemployer: SelectOptionsClass<BasicSelectOption> = new SelectOptionsClass<BasicSelectOption>();
}
export class professionalclientstatusTextInput {
    relevantemployername: string = "";
    howroleprovidedexperience: string = "";
    Otherroleholdwithemployer: string = "";

}
export class professionalclientstatusRadioBtns {
    SIZEOFPORTFOLIO: boolean = false;
    workfinancialsector: boolean = false;
    employerregulatedfinancial: boolean = false;
    tradedsignificantsize: boolean = false;
}
export class professionalclientstatuscheckBox {
    iunderstand: boolean = false;
}
export class TheTypesOfFinancialInstrumentsCheckboxGroup {
    Currencies: boolean = false;
    Commodities: boolean = false;
    Indices: boolean = false;
    Stocks: boolean = false;
    CryptoCurrency: boolean = false;
}
export class tradingexperienceCheckboxes {
    TheTypesOfFinancialInstrumentsList: string[] = [];
    TheTypesOfFinancialInstrumentsCheckboxGroup: TheTypesOfFinancialInstrumentsCheckboxGroup = new TheTypesOfFinancialInstrumentsCheckboxGroup();
}
export class tradingexperienceRadioBtns {
    DoYouHaveGoodUndersOfleveragedProducts: boolean = false;
    DoYouHaveGoodUndersOfvolatilityconcepts: boolean = false;
    DoYouHaveQualificationOfleveragedProducts: boolean = false;
    DoYouHaveunderstandingofrisks: boolean = false;
    professionrelatefinancialindustryqsn: boolean = false;
    usedleveragedproductsqsn: boolean = false;
}
export class ReviewSubmitcheckBox {
    IConfirmInformationIsAccurate: boolean = false;
    ModalRisk: boolean = false;
}
//Add kycReq
export interface KYCClass {
    KYCID: number;
    ClientID: string;
    IsAdmin: boolean;
    KYCStatus: string;
    Reason: string;
    Status: number;
    AdminUsername: string;
    ESignature: string;
    ESignatureBinary: string;
    RiskWarning: boolean;
    TotalScore: number;
    PersonalDetails: PersonalDetails;
    InvestorInfo: InvestorInfo;
    TradingExperience: TradingExp;
    TradingKnowledge: TradingKnowledge;
    TraderDeclaration: TraderDeclaration[];
    Documents: Documents;
    ProfClntStatus: ProfClntStatus;
}

export interface PersonalDetails {
    Salutation: number;
    SalutationTxt: string;
    FirstName: string;
    MiddleName: string;
    LName: string;
    DOB: string | null;
    Phone: string;
    SecondPhone: string;
    Email: string;
    CountryOfBirthID: number;
    NationalityID: number;
    MaritalStatus: string;
    USAResidentQsn: boolean;
    PoliticallyPersonQsn: boolean;
    DeclaredBankruptcyQsn: boolean;
    USATaxPurposesQsn: boolean;
    Country: number;
    ResidentialAddress: string;
    State: string;
    City: string;
    StreetName: string;
    BuildingNumber: string;
    PostalCode: string;
}

export interface InvestorInfo {
    Education: string;
    AmountToInvest: string;
    LiquidNetWorth: string;
    AnnualNetIncome: string;
    AnnualIncome: string;
    TotalPortfolio: string;
    NetWorth: string;
    PlannedInvestment: string;
    PurposeInvesting: string;
    NumberOfDependents: string;
    AmountToInvest12Months: string;
    BusinessOwnership: boolean;
    FamilyIncomeRelationship: boolean;
    InsurancePayout: boolean;
    ReturnInvestmentsSharesDividends: boolean;
    PropertyLandInvestments: boolean;
    Inheritance: boolean;
    IncomeEmployment: boolean;
    BankingFacilities: boolean;
    PersonalSaving: boolean;
    CommissionsRevenuesBonuses: boolean;
    OtherSrcWlth: boolean;
    OtherSrcWlthTxt: string;
    TaxResidencyCountry: number;
    TaxNumber: boolean;
    TaxNumberTxt: string;
    WorkExperienceQsn: boolean;
    AttendedCoursesQsn: boolean;
    NationalPassportQsn: boolean;
    CurrentEmployer: string;
    EmploymentStatus: string;
    CurrentPosition: string;
    Industry: string;
    NatureOfBusiness: string;
    Occupation: string;
    EmploymentAddress: string;
    EmploymentCity: string;
    EmploymentCountry: string;
    EmploymentPostalCode: string;
    SecondaryIncomeQsn: boolean;
    SourceOfIncome: string;
    PreviousEmployer: string;
    IndustryRetired: string;
    PreviousPosition: string;
    SourceOfIncomeStudent: string;
    IndustryStudent: string;
    MonthlyExpenses: string;
    EstimatedAnnualInvestment: string;
    NetValueWealth: string;
    PlannedInitialInvestment: string;
    Profession: string;
    NameEmployerOrganisation: string;
    CountryBankAccount: number;
    BusinessOwnershipSF: boolean;
    FamilyIncomeRelationshipSF: boolean;
    InsurancePayoutSF: boolean;
    ReturnInvestmentsSharesDividendsSF: boolean;
    PropertyLandInvestmentsSF: boolean;
    InheritanceSF: boolean;
    IncomeEmploymentSF: boolean;
    BankingFacilitiesSF: boolean;
    PersonalSavingSF: boolean;
    CommissionsRevenuesBonusesSF: boolean;
    OtherSourceFunds: boolean;
    OtherSourceFundsTxt: string;
    ValueRentalProp: string;
    MonthlyRentalIncome: string;
    IndustryStudentOther: string;
    IndustryEmpOther: string;
    IndustryRetiredOther: string;
    TINUnavailableTXT: string;
    TINUnavailable: string;
}

export interface TradingExp {
    MonthlyAverageVolume: string;
    PurposeNatureTrading: string;
    PurposeOpeningAcc: string;
    LevelKnowledgeFinancialMarkets: string;
    LongHaveTraded: string;
    TradesPerMonth: string;
    TimeframeHeldPositions: string;
    TypeInstrumentsFamiliar: string;
    IFCurrencies: boolean;
    IFCommodities: boolean;
    IFIndices: boolean;
    IFStocks: boolean;
    IFCryptoCurrency: boolean;
    WhatExtentTradedFunds: string;
    WhatExtentTradedDerivatives: string;
    WhatExtentTradedWithMargin: string;
    AverageYearlyVolume: string;
    AverageLevelLeverage: string;
    UnderstandLeveragedProductsQsn: boolean;
    QualificationLeveragedQsn: boolean;
    UnderstandRisksWithTradingQsn: boolean;
    UnderstandConceptsVolatilityQsn: boolean;
    MaximumLeverageUsed: string;
    ProfessionRelateFinancialIndustryQsn: boolean;
    UsedLeveragedProductsQsn: boolean;
}

export interface TradingKnowledge {
    StopLossOrderUsedQns: string;
    RiskiestInvestmentQns: string;
    MarginRequiredQns: string;
    StoppedOutSituationsQns: string;
    TradingHigherLeverageApplyQns: string;
    HoldProfessionalQualification: boolean;
    ReceivedHigherFinanceEducation: boolean;
    HoldBachelorOtherSubjects: boolean;
    WorkedRoleRelevantTrading: boolean;
    NoneAbove: boolean;
    EducationalFinancial: string;
    EducationalFinancialTXT: string;
    TradingHigherLeverageQns: boolean;
    InitialPositionQns: boolean;
    FuturesMarketQns: boolean;
    OTCDerivativesDifferentQns: boolean;
    OTCDerivativesPlacedQns: boolean;
    ForeignExchangeLevel: string;
    ForeignExchangeYears: string;
    ForeignExchangeTradesNo: string;
    SharesLevel: string;
    SharesYears: string;
    SharesTradesNo: string;
    MutualFundsOfSharesLevel: string;
    MutualFundsOfSharesYears: string;
    MutualFundsOfSharesTradesNo: string;
    GovernmentCorporateBondsLevel: string;
    GovernmentCorporateBondsYears: string;
    GovernmentCorporateBondsTradesNo: string;
    CFDsLevel: string;
    CFDsYears: string;
    CFDsTradesNo: string;
    CommoditiesPreciousMetalsLevel: string;
    CommoditiesPreciousMetalsYears: string;
    CommoditiesPreciousMetalsTradesNo: string;
    OptionsOrFuturesLevel: string;
    OptionsOrFuturesYears: string;
    OptionsOrFuturesTradesNo: string;
    OtherInvestmentsLevel: string;
    OtherInvestmentsYears: string;
    OtherInvestmentsTradesNo: string;
    HowTradedTheseProducts: string;
    StopOutBeTriggered: string;
    TrueAboutFuturesQns: string;
    WhySpreadFloatingQns: string;
}

export interface TraderDeclaration {
    DID: number;
    Answer: boolean;
}

export interface ProfClntStatus {
    TradedSignificantSize: boolean;
    SizeFinancialPortfolio: boolean;
    WorkFinancialSector: boolean;
    ProvideAverageNumber: string;
    RelevantEmployerName: string;
    EmployerRegulatedFinancial: boolean;
    HowRoleProvidedExperience: string;
    RoleHoldWithEmployer: string;
    RoleHoldWithEmployerOther: string;
    IUnderstand: boolean;
}
export interface ProfileInfo {
    ContactID: number;
    ClientID: string;
    BUID: number;
    Type: string;
    BinaryDocument: string;
    Password: string;
    CurrPassword: string;
    Email: string;
    Language: string;
    WLName: string;
    PoolID: number;
    Code: string;
}
//#region appform Json
export const DefaultValueDict: any = {
    "dropdown": new BasicSelectOption(),
    "dynamicDropdown": new BasicSelectOption(),//x
    "multiDropdown": [],
    "input": "",
    "boolean": false,
    "radiogroup": '',
    "radiogroupCard": '',//client docuemnts
    "date": new Date(),
    "file": { imgsrc: "", placeholder: "" },
    "html": "",//label
    "listDeclaration": "",
};
//#endregion